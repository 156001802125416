import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import styles from "./figure.module.css";

export const Figure = ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const gatsbyImageData = getGatsbyImageData(node, { maxWidth: 675 }, clientConfig.sanity);
  return (
    <figure style={{ maxWidth: `${node.maxWidth}px` }}>
      <GatsbyImage image={gatsbyImageData} alt={node.alt} />
      <figcaption className={styles.figCaption}>{node.caption}</figcaption>
    </figure>
  );
};
