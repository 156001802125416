import React from "react";

const Logo = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.97 126.04"
    fill="currentColor"
  >
    <ellipse cx="36.74" cy="112.06" rx="24.96" ry="12.48" fill="#fff" />
    <path
      d="M38.23,127.55c-14.84,0-26.46-6.14-26.46-14s11.62-14,26.46-14,26.46,6.14,26.46,14S53.07,127.55,38.23,127.55Zm0-25c-12.72,0-23.46,5-23.46,11s10.74,11,23.46,11,23.46-5,23.46-11S51,102.59,38.23,102.59Z"
      transform="translate(-1.49 -1.51)"
    />
    <path
      d="M39,3A35.85,35.85,0,0,1,69.25,58.27L39,105.35,8.72,58.27A35.84,35.84,0,0,1,39,3Z"
      transform="translate(-1.49 -1.51)"
      fill="#fff"
    />
    <path
      d="M39,108.12l-31.52-49A37.34,37.34,0,0,1,39,1.51h.13a37.35,37.35,0,0,1,31.4,57.57ZM38.84,4.51A34.35,34.35,0,0,0,10,57.46l29,45.12L68,57.46A34.35,34.35,0,0,0,39.11,4.51h-.27Z"
      transform="translate(-1.49 -1.51)"
    />
    <path
      d="M20.29,33.89a5.5,5.5,0,1,1,10.88-1.62,5.63,5.63,0,0,1,0,1.23v.17h-3V33.3a2.94,2.94,0,0,0,0-.59,2.5,2.5,0,0,0-4.95.74Z"
      transform="translate(-1.49 -1.51)"
    />
    <path
      d="M54.68,33.85a2.5,2.5,0,0,0-5,0l-3-.36a5.5,5.5,0,0,1,10.92,0Z"
      transform="translate(-1.49 -1.51)"
    />
    <path
      id="Path_8"
      data-name="Path 8"
      d="M53,44.42A44.77,44.77,0,0,1,38.42,48.8a32.21,32.21,0,0,1-14.55-4.38"
      transform="translate(-1.49 -1.51)"
      fill="#fff"
    />
    <path
      d="M38.47,50.3h-.13a33.84,33.84,0,0,1-15.23-4.59l1.52-2.58a30.88,30.88,0,0,0,13.74,4.16,42.94,42.94,0,0,0,13.94-4.21l1.34,2.68a46.16,46.16,0,0,1-15,4.53Z"
      transform="translate(-1.49 -1.51)"
    />
  </svg>
);

export default Logo;
