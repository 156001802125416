import React from "react";
import Helmet from "react-helmet";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

function SEO({ lang, fullUrl, metaTitle, metaDescription, image }) {
  let linkTags = [
    {
      rel: "canonical",
      href: fullUrl,
    },
  ];

  metaTitle = metaTitle || "";
  metaDescription = metaDescription || "";
  const metaImage = image && image.asset ? imageUrlFor(buildImageObj(image)).width(1200).url() : "";

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      link={linkTags}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: metaImage,
        },
        {
          property: "og:url",
          content: fullUrl,
        },
      ]}
    />
  );
}

export default SEO;
