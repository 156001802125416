import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn, toPlainText } from "../lib/helpers";

import styles from "./header.module.css";

const Header = ({ onHideNav, onShowNav, showNav, siteName }) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <Link title="Terug naar de homepagina" to="/" className={styles.branding}>
        <Icon symbol="logo" className={styles.logo} />
        <span>{toPlainText(siteName)}</span>
      </Link>

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol="hamburger" />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link title="Leer Jojo kennen" to="/over" className={styles.branding}>
              Over Jojo
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default Header;
