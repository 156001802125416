import React from "react";
import { Figure } from "./Figure";

const serializers = (hardBreak) => {
  let serializersObj = {
    types: {
      authorReference: ({ node }) => <span>{node.author.name}</span>,
      mainImage: Figure,
    },
    marks: {
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href, title } = mark;
        return blank ? (
          <a href={href} title={title} target="_blank" rel="noopener">
            {children}
          </a>
        ) : (
          <a href={href} title={title}>
            {children}
          </a>
        );
      },
    },
  };

  if (hardBreak) {
    serializersObj.hardBreak = hardBreak;
  }

  return serializersObj;
};

export default serializers;
