import React from "react";
import HamburgerIcon from "./hamburger";
import LogoIcon from "./logo";

function Icon({ symbol, className }) {
  switch (symbol) {
    case "logo":
      return <LogoIcon className={className} />;
    case "hamburger":
      return <HamburgerIcon className={className} />;
    default:
      return <span>Unknown icon: {symbol}</span>;
  }
}

export default Icon;
